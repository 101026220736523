@import "./../function";

.subpage {
    width: 100%;

    &__heading {
        padding-top: rem(50);

        @include media-breakpoint-down(lg) {
            padding-top: rem(30);
        }
    }

    &__content {
        margin-top: rem(20);
        margin-bottom: rem(70);
        min-height: 450px;

        @include media-breakpoint-down(lg) {
            min-height: 250px;
        }

        a {
            text-decoration: none;
            color: #777777;
            transition-duration: 0.3s;

            &:hover {
                transition-duration: 0.3s;
                color: #000;
            }
        }

        p {
            font-family: 'Raleway', sans-serif !important;
        }

        h1 {
            font-size: rem(36);
            font-weight: 600;
            font-family: 'Raleway', sans-serif !important;
            margin-bottom: rem(40);

            @include media-breakpoint-down(lg) {
                margin-bottom: rem(20); 
            }
        }

        span {
            display: flex !important;

            img {
                cursor: pointer;
                width: 100% !important;
                min-height: rem(300) !important;
                max-height: rem(300) !important;
                -o-object-fit: cover;
                object-fit: cover;
                top: rem(20);
                z-index: 0;
            }
        }

        &__realizations-item {

            &--single {
                width: 100% !important;
                background-size: 100% !important;
                background-size: cover !important;
                background-repeat: no-repeat !important;
                min-height: rem(300);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                justify-content: flex-end;
                align-content: stretch;
                align-items: flex-start;

                span {
                    order: 0;
                    flex: 0 1 auto;
                    align-self: auto;
                    padding: rem(20);
                    color: #fff;
                    text-decoration: none;
                    font-weight: 600;
                }
            }



        }
    }
}

a {
    text-decoration: none !important;
}

.btl-80-img {
    border-top-left-radius: rem(80);
}

.grecaptcha-badge {
    display: none !important;
}

.btl-80-img {
    border-top-left-radius: rem(80);
}

.grecaptcha-badge {
    display: none !important;
}

.fancybox__thumbs {
    display: none;
}

.fancybox__button--zoom {
    display: none !important;
}

.fancybox__button--slideshow {
    display: none !important;
}

.fancybox__button--fullscreen {
    display: none !important;
}

.fancybox__button--thumbs {
    display: none !important;
}

.fancybox__toolbar__items--left {
    display: none !important;
}